import React from 'react'
import Hero from '../components/Hero'
import InfoSection from '../components/InfoSection'
import Layout from '../components/layout'
import Button from '../components/Button'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import loadable from '@loadable/component'
import ProjectSections from '../components/ProjectSections'

const USASectionComponent = loadable(() => import('../components/USASection'))
const ServicesDisplayComponent = loadable(() =>
  import('../components/ServicesDisplay')
)

const IndexPage = ({ data }) => (
  <Layout>
    <Hero headerImage={data.headerImage} />
    <Helmet
      title="Home | NEXTGEN Code Company"
      meta={[{ name: 'description', content: 'Software development agency.' }]}
    >
      <meta
        name="google-site-verification"
        content="PW_oZBRJjJ_Qj8LvA_Vw-8oRvRGuMVjQMxiZMO4naGE"
      />
    </Helmet>
    <InfoSection />

    {data.allContentfulProject.edges.map(edge => (
      <ProjectSections key={edge.node.title} node={edge.node} />
    ))}
    <div className="project-button-wrapper">
      <Button to="/projects" color="dark" text="View More Projects" />
    </div>
    <USASectionComponent />
    <div className="container ng-home-servicesDisplay">
      <h1>Our most common services</h1>
      <ServicesDisplayComponent />
    </div>
  </Layout>
)

export default IndexPage

export const IndexQuery = graphql`
  query IndexQuery {
    allContentfulProject(
      filter: { node_locale: { eq: "en-US" }, showOnHomePage: { eq: true } }
    ) {
      edges {
        node {
          title
          slug
          type
          backgroundImage {
            resolutions(width: 1080) {
              width
              height
              src
            }
            sizes(maxWidth: 1800) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
          mockup {
            sizes(maxWidth: 1800) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
        }
      }
    }
  }
`
