import React, { Component } from 'react'
import Button from '../components/Button'
import { Link } from './Link'

class InfoSection extends Component {
  render() {
    return (
      <section className="section info-section">
        <div
          className="container"
          style={{ marginBottom: '3rem', zIndex: '3' }}
        >
          <div className="columns">
            <div className="column">
              <h1 className="hero-title">
                Why we{' '}
                <span
                  style={{ fontFamily: 'IBM Plex Mono', fontWeight: '400' }}
                >
                  code
                </span>
              </h1>
              <p className="code-box-brackets ">
                <br />
                <b>We want to build a cooler future.</b> Imagine the technology
                you'd see in a sci-fi movie... Or even just 30 years from now...
                We want to see that happen sooner and the best way to do that
                is, speed up technology adoption.
                <br />
                <br />
              </p>
            </div>

            <div className="column">
              <div className="corner-img">
                <div className="img-frame" style={{ paddingBottom: '71%' }}>
                  <img
                    className="corner-img-child"
                    src="https://res.cloudinary.com/devhound/image/upload/c_fill,f_auto,g_center,h_571,q_auto,w_800/v1529929438/zany-jadraque-571205-unsplash_auoson.jpg"
                    width="800"
                    height="571"
                    alt="info section 1"
                  />
                </div>
              </div>
              <div className="corner-img">
                <div className="img-frame" style={{ paddingBottom: '71%' }}>
                  <img
                    className="corner-img-child"
                    src="https://res.cloudinary.com/devhound/image/upload/c_fill,f_auto,h_571,q_auto,w_800/v1529929623/About_Us_Office-25_rwbiqn.jpg"
                    width="800"
                    height="571"
                    alt="info section 2"
                  />
                </div>
              </div>
            </div>
          </div>
          <h1 className="text-align-left">
            We are the extension of your company often referred to as, "my tech
            guys".
          </h1>
          <div className="columns">
            <div className="column">
              <p className="code-box-brackets ">
                <br />
                Looking for a CTO? Or a freelancer? Coder? Developer?
                Consultant? D). All of the above?
                <br />
                <br />
                <b>That's us. </b>
                <br />
                <br /> Here's <Link to="/services">what we do.</Link>
                <br />
                Here's <Link to="/projects">what we've done.</Link>
                <br />
                <br />
              </p>
            </div>
            <div className="column">
              <div className="corner-img">
                <div className="img-frame" style={{ paddingBottom: '71%' }}>
                  <img
                    className="corner-img-child"
                    src="https://res.cloudinary.com/tylerholden/image/upload/c_fill,f_auto,g_center,h_571,q_auto,w_800/v1557243234/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/index_img_4.jpg"
                    width="800"
                    height="571"
                    alt="info section 1"
                  />
                </div>
              </div>
              <div className="corner-img">
                <div className="img-frame" style={{ paddingBottom: '71%' }}>
                  <img
                    className="corner-img-child"
                    src="https://res.cloudinary.com/tylerholden/image/upload/c_fill,f_auto,h_571,q_auto,w_800/v1557243234/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/index_img_3.jpg"
                    width="800"
                    height="571"
                    alt="info section 2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default InfoSection
