import React, { Component } from 'react'

class Hero extends Component {
  render() {
    return (
      <div className="ng-header-new">
        <div className="ng-header-title-new">
          NEXT<b className="ng-bold-700">GEN</b>{' '}
          <span className="ng-code-company-title">Code Company</span>
        </div>
        <div className="ng-header-track-new">
          <div className="ng-header-item-new item-1">
            <div className="ng-header-mobile-new">
              <img
                alt="townwave mockup"
                src="https://res.cloudinary.com/devhound/image/upload/f_auto,q_auto/v1531845968/townwave-header_jvm1n3.jpg"
              />
            </div>
            <div className="ng-header-item-title">TownWave</div>
            <div className="ng-header-item-subtitle">
              Mobile App | Web App | Website
            </div>
          </div>
          <div className="ng-header-item-web-new item-2">
            <div className="ng-header-web-new">
              <img
                alt="sure pick sports web mockup"
                src="https://res.cloudinary.com/devhound/image/upload/f_auto,q_auto/v1531845968/sure-pick-sports-header_i7kniw.jpg"
              />
            </div>
            <div className="ng-header-item-title">Sure Pick Sports</div>
            <div className="ng-header-item-subtitle">Website</div>
          </div>
          <div className="ng-header-item-new item-3">
            <div className="ng-header-mobile-new">
              <img
                alt="vetgraft mockup"
                src="https://res.cloudinary.com/devhound/image/upload/f_auto,q_auto/v1531845967/vetgraft-header_vybd9c.jpg"
              />
            </div>
            <div className="ng-header-item-title">VetGraft</div>
            <div className="ng-header-item-subtitle">Mobile App | ERP</div>
          </div>
          <div className="ng-header-item-web-new item-4">
            <div className="ng-header-web-new">
              <img
                alt="mirra skincare"
                src="https://res.cloudinary.com/tylerholden/image/upload/v1545871899/mirra_skincare_hero.jpg"
              />
            </div>
            <div className="ng-header-item-title">Mirra Skincare</div>
            <div className="ng-header-item-subtitle">Shopify Storefront</div>
          </div>
          <div className="ng-header-item-new item-5">
            <div className="ng-header-mobile-new">
              <img
                alt="my patient express mockup"
                src="https://res.cloudinary.com/devhound/image/upload/f_auto,q_auto/v1531845968/my-patient-express-header_h0jh7o.jpg"
              />
            </div>
            <div className="ng-header-item-title">MyPatientExpress</div>
            <div className="ng-header-item-subtitle">Mobile App | Web App</div>
          </div>
        </div>

        <div className="ng-menu-progress-bar"></div>

        <div className="ng-menu-spacer-spot"></div>
      </div>
    )
  }
}

export default Hero
